import { regex } from 'vuelidate/lib/validators/common';
import { or } from 'vuelidate/lib/validators';

const sunbedSerial = regex('sunbedSerial', /^[0-9]{2}[A-F0-9]{3}[0-9]{6}[A-F0-9]{4}$/);
const legacySunbedSerial = regex('legacySunbedSerial', /^([0-9]{2}[a-zA-Z]{2}[0-9]{5}[0-9]{6})$/);
const isSunbedSerial = or(sunbedSerial, legacySunbedSerial);

const semver = regex('semver', /(?:\sv)?(?:(?:0|[1-9]\d*)\.){2}(?:0|[1-9]\d*)(?:-(?:0|[1-9]\d*|[\da-z-]*[a-z-][\da-z-]*)(?:\.(?:0|[1-9]\d*|[\da-z-]*[a-z-][\da-z-]*))*)?(?:\+[\da-z-]+(?:\.[\da-z-]+)*)?\b/);
const pincode = regex('pincode', /^[0-9]{4}$/);
const hhmm = regex('hhmm', /^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/);
const minuteSeconds = regex('mmss', /^([0-5][0-9]):([0-5][0-9])$/);
const moduleID = regex('moduleID', /^ML(?:[a-fA-F0-9]{3})(?:[0-9]{2})(?:M[0-9]{2})?(?:[a-fA-F0-9]{6,})(?:[a-fA-F0-9]{4})$/i);

export {
  sunbedSerial,
  legacySunbedSerial,
  isSunbedSerial,
  semver,
  pincode,
  hhmm,
  minuteSeconds,
  moduleID,
};

export default {
  sunbedSerial,
  legacySunbedSerial,
  isSunbedSerial,
  semver,
  pincode,
  hhmm,
  minuteSeconds,
  moduleID,
};
