<template>
  <div class="d-flex flex-column flex-root">
    <div class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white">
      <!--begin::Aside-->
      <div class="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-15 bg-dark">
        <!--begin: Aside Container -->
        <div class="d-flex flex-row-fluid flex-column justify-content-between">
          <!--begin: Aside header -->
          <a href="#" class="flex-column-auto">
            <img :src="require('@/assets/images/logo/myluxura-logo.light.svg')" class="h-65px w-auto" />
          </a>
          <!--end: Aside header -->
          <!--begin: Aside content -->
          <div class="flex-column-fluid d-flex flex-column justify-content-center">
            <img :src="require('@/assets/images/module/myluxura-module.sticker-id.svg')" class="w-100 h-auto d-none d-lg-inline" />
          </div>
          <!--end: Aside content -->
          <!--begin: Aside footer for desktop -->
          <div class="d-none flex-column-auto d-lg-flex justify-content-between mt-15">
            <a href="https://vdlhapro.com" target="_blank" class="opacity-70 font-weight-bold text-white">
              &copy; {{ new Date().getFullYear() }} VDL Hapro
            </a>

            <div class="d-flex">
              <a href="#" class="text-white">Privacy</a>
              <a href="#" class="text-white ml-10">Legal</a>
              <a href="#" class="text-white ml-10">Contact</a>
            </div>
          </div>
          <!--end: Aside footer for desktop -->
        </div>
        <!--end: Aside Container -->
      </div>
      <!--begin::Aside-->

      <!--begin::Content-->
      <div class="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
        <div class="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
          <div class="w-100">
            <!--begin::Content header-->
            <div class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">

            </div>
            <!--end::Content header-->

            <!--begin::Signin-->
            <div class="row">
              <div class="col-12 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3 col-xxl-4 offset-xxl-4 login-form login-signin">
                <div class="text-center mb-10">
                  <h3 class="font-size-h1">Local MyLuxura</h3>
                  <p class="text-muted font-weight-semi-bold">
                    Enter the module ID
                  </p>
                </div>

                <!-- begin::Error alert -->
                <div v-if="isErrorActive" class="my-10">
                  <error-translation-alert
                    ref="errorTranslationAlert"
                    variant="light-danger"

                    :error="activeError"
                  />
                </div>
                <!-- end::Error alert -->

                <!--begin::Form-->
                <form class="form" @submit.stop.prevent="onSubmit">
                  <div class="form-group">
                    <input
                      ref="serial"
                      type="text"
                      placeholder="Module ID"
                      :class="['form-control form-control-solid h-auto py-5 px-6', {'is-invalid': $v.form.serial.$error}]"
                      :disabled="isLoading"
                      v-model="$v.form.serial.$model"
                    />
                  </div>

                  <!--begin::Action-->
                  <div class="form-group d-flex flex-wrap justify-content-between align-items-center">
                    <button
                      :disabled="isLoading"
                      ref="kt_login_signin_submit"
                      class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3 ml-auto"
                    >
                      Connect
                    </button>
                  </div>
                  <!--end::Action-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Signin-->
            </div>
          </div>
        </div>
      </div>
      <!--end::Content-->
    </div>

    <!-- begin::Modals -->
    <information-modal ref="informationModal" />
    <!-- end::Modals -->
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "~@vedicium/metronic-vue/src/assets/sass/pages/login/login-1.scss";
</style>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { moduleID } from '@/libs/validators';
import { validations, errorComponentMixin } from '@vedicium/core-vue';

import Device from '@/libs/classes/device';

import { InformationModal } from '@vedicium/metronic-vue';
import errorTranslationAlert from '@/components/errors/translation.alert.vue';

export default {
  mixins: [validationMixin, errorComponentMixin],
  name: 'internalSerialConnection',
  components: {
    InformationModal,

    errorTranslationAlert,
  },
  data () {
    return {
      isLoading: false,

      form: {
        serial: null,
      },
    };
  },
  validations: {
    form: {
      serial: {
        required,
        moduleID,
      },
    },
  },

  async mounted () {
    // Add email if set by query
    if (this.$route.query && this.$route.params.serial) {
      this.$set(this.form, 'serial', this.$route.params.serial);
    }

    await this.$nextTick();
    this.$refs.serial.focus();

    // Try to connect
    if (this.form.serial && this.form.serial.length > 0) {
      try {
        await this.validate();
        await this.onSubmit();
      } catch (e) {
        console.error(e);
      }
    }
  },

  methods: {
    async validate () {
      const validationArray = [this.$v];

      // Run the validations
      await validations.validateArray(validationArray);
    },
    async resetForm () {
      this.$errors.clear({ component: this });
      this.$set(this, 'form', { serial: null });
      await this.$nextTick();
      this.$v.$reset();
    },
    async onSubmit () {
      this.$errors.clear({ component: this });
      const submitButton = this.$refs.kt_login_signin_submit;
      submitButton.classList.add('spinner', 'spinner-light', 'spinner-right');
      this.$set(this, 'isLoading', true);

      // First, validate form
      try {
        await this.validate();
      } catch (e) {
        this.$errors.handle(e, { component: this, ui_element: false });
        console.error(e);

        // Don't do this in 'finally', because it should only stop when it errors
        submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right');
        this.$set(this, 'isLoading', false);

        return;
      }

      // Get network address based on serial
      try {
        // Replace parameter, so the URL is correct after a refresh
        if (this.$route.params.serial !== this.form.serial) {
          this.$router.replace({ params: { serial: this.form.serial } });
        }

        // Gather data
        const { data } = await this.$http.get(`${Device.uri}/public/${this.form.serial.toUpperCase()}`);
        if (!data.network || data.network.length === 0) {
          throw new Error('UndefinedNetwork');
        }

        const errors = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const localAddress of data.network) {
          try {
            // eslint-disable-next-line no-await-in-loop
            await this.$http.get(`http://${localAddress}/api/system/healthcheck`, {}, { timeout: 2500 });
            setImmediate(() => window.location.assign(`http://${localAddress}`));
            return;
          } catch (e) {
            errors.push(e);
          }
        }

        throw errors[0] || new Error('UndefinedError');
      } catch (e) {
        // this.$errors.handle(e, { component: this, ui_element: true });
        console.error(e);

        this.$metronic.eventhub.emit('modals:layout:information:open', {
          title: 'Unable to connect to MyLuxura Module',

          // eslint-disable-next-line no-multi-str
          message: '<div class="alert-text"> \
              There seems to be no MLM on this network with this ID. Please make sure that: <br /> \
              <ul> \
                <li>You filled out the ID exactly as shown on the sticker.</li> \
                <li>You are connected to the same network as the MLM.</li> \
                <li>The MLM is connected to a (wireless) network, if not, connect to the MLM’s access point as instructed on the sticker.</li> \
              </ul>\
            </div>',

          classes: ['alert alert-custom alert-light-info mb-0'],
        });

        return;
      } finally {
        submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right');
        this.$set(this, 'isLoading', false);
      }
    },
  },
};
</script>
